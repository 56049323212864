import {AnalyticsBrowser} from '@customerio/cdp-analytics-browser';

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: 'b2243a5d719b558ce98f',
  },
  {
    integrations: {
      'Customer.io Data Pipelines': {
        apiHost: 'cio.fanathem.com/v1',
      },
    },
  },
);

export default function Analytics() { return null; }

