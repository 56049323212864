import type {Tracer} from 'dd-trace';

let tracer: Tracer | undefined;

if (typeof window === 'undefined' && process.env.AWS_EXECUTION_ENV) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    tracer = require('dd-trace').tracer;
  } catch (e) {
    console.error('Unable to load dd-trace', e)
  }
}

export default tracer;
