import logo from '~/logo.png';
import {Button, ButtonProps} from '~/components/ui/Button';
import {Box, Container, Flex, HStack, VStack} from '@styled-system/jsx';
import {css} from '@styled-system/css';
import {Link, NavLink, useLocation} from '@remix-run/react';
import HamburgerIcon from '../../icons/hamburger.svg?react';
import {Sheet, SheetClose, SheetContent, SheetTrigger} from '~/components/ui/Sheet';
import {ReactNode} from 'react';
import {UserDetails} from '~/services/auth.server';

type HeaderProps = {
  user: UserDetails | null
}

const HeaderButtonLink = ({to, children, ...rest}: { to: string, children: ReactNode } & ButtonProps) => {
  return <Button variant="ghost" asChild className={css({textTransform: 'none'})} {...rest}>
    <NavLink to={to} className={css({
      textUnderlineOffset: '8px',
      textDecoration: 'none',
      textDecorationColor: 'primary',
      _currentPage: {
        textDecorationLine: 'underline',
      }
    })}>
      {children}
    </NavLink>
  </Button>
}

const Header = ({user}: HeaderProps) => {
  const location = useLocation();

  return <Box py={4} px={0} bg="black" color="white">
    <Container>
      <HStack justify="space-between">
        <HStack justify="flex-end" flex={1} hideBelow="md">
          <HeaderButtonLink to="/promotions">Promotions</HeaderButtonLink>
          <HeaderButtonLink to="/winners">Winners</HeaderButtonLink>
        </HStack>
        <Link to="/">
          <img
            className={css({h: 10, lg: {px: 4}, base: {px: 0}})}
            src={logo}
            alt="Fanathem"
          />
        </Link>
        <HStack justify="space-between" flex={1} hideBelow="md">
          <HStack justify="flex-start">
            <HeaderButtonLink to="/vip-club">VIP Club</HeaderButtonLink>
            <HeaderButtonLink to="/about">About Us</HeaderButtonLink>
          </HStack>
          <Button justifySelf="flex-end" asChild>
            <Link to={user ? '/portal' : `/auth/login?redirectTo=${encodeURIComponent(location.pathname)}`}>
              {user ? 'Profile' : 'Login'}
            </Link>
          </Button>
        </HStack>
        <Sheet>
          <SheetTrigger asChild>
            <Button hideFrom="md" variant="icon">
              <HamburgerIcon/>
            </Button>
          </SheetTrigger>
          <SheetContent width="full" bga="black/80" color="white" p={0}>
            <VStack width="full" height="full">
              <Box py={4} px={0} bg="black" color="white" width="full">
                <Container>
                  <HStack justify="space-between" py={2}>
                    <Link to="/">
                      <img
                        className={css({h: 7, lg: {px: 4}, base: {px: 0}})}
                        src={logo}
                        alt="Fanathem"
                      />
                    </Link>
                  </HStack>
                </Container>
              </Box>
              <Flex flex={1} justifyContent="center" alignItems="center" width="full">
                <VStack maxWidth="250px" width="full">
                  <SheetClose asChild>
                    <HeaderButtonLink to="/promotions">Promotions</HeaderButtonLink>
                  </SheetClose>
                  <SheetClose asChild>
                    <HeaderButtonLink to="/winners">Winners</HeaderButtonLink>
                  </SheetClose>
                  <SheetClose asChild>
                    <HeaderButtonLink to="/vip-club">VIP Club</HeaderButtonLink>
                  </SheetClose>
                  <SheetClose asChild>
                    <HeaderButtonLink to="/about">About Us</HeaderButtonLink>
                  </SheetClose>
                  <SheetClose asChild>
                    <Button width="full" justifySelf="flex-end" asChild>
                      <Link to={user ? '/portal' : `/auth/login?redirectTo=${encodeURIComponent(location.pathname)}`}>
                        {user ? 'Profile' : 'Login'}
                      </Link>
                    </Button>
                  </SheetClose>
                </VStack>
              </Flex>
            </VStack>
          </SheetContent>
        </Sheet>
      </HStack>
    </Container>
  </Box>;
}

export default Header;
